























// CORE
import { Component, Mixins } from 'vue-property-decorator'

// LIBRARIES
import Draggable from 'vuedraggable'

// MIXINS
import SystemMixin from '@/mixins/SystemMixin'

// COMPONENTS
import Card from '@/components/cards/Card.vue'

// INTERFACES
import { IWidgetSetting } from '@/store/types'

// STORE
import FleetWidgetsModule from '@/store/modules/fleet/widgets'
import DriverWidgets from '@/store/modules/driver/driver-widgets'

@Component({
  components: {
    Card,
    Draggable,
  },
})
export default class WidgetsSettingsCard extends Mixins(SystemMixin) {
  private get widgets (): IWidgetSetting[] {
    if (this.role === 'fleet') {
      return FleetWidgetsModule.widgetsSettings
    } else {
      return DriverWidgets.widgetsSettings
    }
  }

  private set widgets (payload: IWidgetSetting[]) {
    if (payload.length && this.entity) {
      if (this.role === 'fleet') {
        FleetWidgetsModule.setWidgetsSettings({ data: payload, entity: this.entity })
      } else {
        DriverWidgets.setWidgetsSettings({ data: payload, entity: this.entity })
      }
    }
  }

  private mounted () {
    if (this.role === 'fleet') {
      FleetWidgetsModule.syncWidgetsSettings(this.entity)
    } else {
      DriverWidgets.syncWidgetsSettings(this.entity)
    }
  }

  private handleChange () {
    if (this.widgets?.length && this.entity) {
      if (this.role === 'fleet') {
        FleetWidgetsModule.setWidgetsSettings({ data: this.widgets, entity: this.entity })
      } else {
        DriverWidgets.setWidgetsSettings({ data: this.widgets, entity: this.entity })
      }
    }
  }

  private handleDraggableStart () {
    document.querySelector('.widgets-settings-card__draggable')?.classList.add('draggable-cursor')
  }

  private handleDraggableEnd () {
    document.querySelector('.widgets-settings-card__draggable')?.classList.remove('draggable-cursor')
  }
}






































import { Component, Mixins } from 'vue-property-decorator'

// MIXIN
import SystemMixin from '@/mixins/SystemMixin'
import ThemeMixin from '@/mixins/ThemeMixin'

// STORE
import ProfileModule from '@/store/modules/profile'

// INTERFACES
import { CarParksResource, CarsResource } from '@/store/types'
import AuthModule from '@/store/modules/auth'

@Component
export default class ProfileCard extends Mixins(SystemMixin, ThemeMixin) {
  private get cars (): CarsResource[] {
    return ProfileModule.userResource.cars
  }

  private get carParks (): CarParksResource[] {
    return ProfileModule.userResource.carParks
  }

  private get carGarages (): CarParksResource[] {
    return ProfileModule.userResource.carGarages
  }

  private get isNotSingleItem (): boolean {
    const isNotOneCar = this.role === 'driver' && this.cars?.length > 1
    const isNotOneCarPark = this.role === 'fleet' && this.carParks?.length > 1
    const isNotOneCarGarage = this.role === 'mechanic' && this.carGarages?.length > 1

    return isNotOneCar || isNotOneCarPark || isNotOneCarGarage
  }

  private get roleTitle (): string {
    if (this.role === 'fleet') {
      return 'Управляющий автопарком'
    } else if (this.role === 'driver') {
      return 'Водитель автомобиля'
    } else if (this.role === 'mechanic') {
      return 'Механик'
    } else {
      return 'Роль не выбрана'
    }
  }

  private get roleLink (): string {
    if (this.role === 'fleet') {
      return 'автопарк'
    } else if (this.role === 'driver') {
      return 'автомобиль'
    } else if (this.role === 'mechanic') {
      return 'автопарк'
    } else {
      return 'Роль не выбрана'
    }
  }

  private get roleType (): string {
    if (this.role === 'fleet') {
      return '№ контрагента'
    } else if (this.role === 'driver') {
      return 'VIN'
    } else if (this.role === 'mechanic') {
      return '№ контрагента'
    } else {
      return 'Роль не выбрана'
    }
  }

  private get roleIcon (): string {
    if (this.role === 'fleet') {
      return 'mdi-account-tie-hat'
    } else if (this.role === 'driver') {
      return 'mdi-account-tie'
    } else if (this.role === 'mechanic') {
      return 'mdi-account-tie-hat'
    } else {
      return 'Роль не выбрана'
    }
  }

  private get isNotOneRole (): boolean {
    return ProfileModule.userRoles.length > 1
  }

  private get itemName (): string {
    if (this.role === 'fleet') {
      return (this.entityFull as CarParksResource)?.name
    } else if (this.role === 'driver') {
      return (this.entityFull as CarsResource)?.carModel
    } else if (this.role === 'mechanic') {
      return (this.entityFull as CarParksResource)?.name
    } else {
      return 'Роль не выбрана'
    }
  }

  private handleLogOut () {
    AuthModule.logOut()
      .then(() => {
        this.$router.push('/auth/login')
      })
  }
}




















































// CORE
import { Component, Vue } from 'vue-property-decorator'

// COMPONENTS
import Card from '@/components/cards/Card.vue'
import CaptionCard from '@/components/cards/CaptionCard.vue'

// STORE
import ProfileModule from '@/store/modules/profile'

// INTERFACES
import { UserAuthResource } from '@/store/types'
import { handlePhoneNumber, handleWorkNumber } from '@/utils/functions'

@Component({
  components: {
    Card,
    CaptionCard,
  },
})
export default class UserInfoCard extends Vue {
  private get userInfo (): UserAuthResource {
    return ProfileModule.userResource.user
  }

  private get userFullName (): string {
    const name = ProfileModule.userResource?.user?.name
    const surname = ProfileModule.userResource?.user?.surname
    const patronymic = ProfileModule.userResource?.user?.patronymic

    let result = ''

    if (surname) {
      result += surname
    }

    if (name) {
      result += ' ' + name
    }

    if (patronymic) {
      result += ' ' + patronymic
    }

    return result.length ? result.trim() : ''
  }

  private handlePhoneNumber = handlePhoneNumber
  private handleWorkNumber = handleWorkNumber
}











// Core
import { Vue, Component } from 'vue-property-decorator'

// Types
import type { Route } from 'vue-router'

// Modules
import SystemModule from '@/store/modules/system'

// Components
import DefaultLayout from '@/components/layouts/DefaultLayout.vue'
import ProfileCard from '@/components/cards/ProfileCard.vue'
import UserInfoCard from '@/components/cards/UserInfoCard.vue'
import WidgetsSettingsCard from '@/components/cards/WidgetsSettingsCard.vue'

@Component({
  components: {
    DefaultLayout,
    ProfileCard,
    UserInfoCard,
    WidgetsSettingsCard,
  },
})
export default class Profile extends Vue {
  private beforeRouteEnter (to: Route, from: Route, next: any) {
    if (from.name === 'auth.registration') {
      SystemModule.openModal({ componentName: 'PersonForm', mod: 'big' })
    }

    next()
  }
}
